/* src/App.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
  }
  
  .resultsList {
    list-style-type: none;
    padding: 0;
  }
  
  .resultItem {
    margin: 5px 0;
  }
  