/* src/components/SearchBar.module.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
  }
  